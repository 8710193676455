import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadShowsRSS } from "../../../redux/showsReducer";
import { loadURL, setCurrentTrackSelection, setCurrentTrackPath, setSliderTime } from "../../../redux/playerReducer";
import { Link } from "react-router-dom";
import arrowUp from "../../../assets/arrow-up.svg"
import * as utils from '../../../utils';



export default function ListOfEpisodes() {
    const episodes = useSelector(state => state.shows);
    
    
    const selectedEpisodeIndex = useSelector(state => state.player.selectedEpisodeIndex)
    const dispatch = useDispatch();
    const currentPath = window.location.pathname;
    const urlArray = currentPath.split("/");
    const currentShowPath = urlArray[2];
    const currentEpisodeIndex = urlArray[3];



    React.useEffect(() => {
        const { parse } = require("rss-to-json");
        const fetchRSS = async () => {
            try {
                const response = await parse(
                    `${utils.rssAPI}${currentShowPath}`
                );
                let reversedEpisodes = response?.items;
                let episodes = reversedEpisodes.reverse();
                dispatch(loadShowsRSS(currentShowPath, episodes));
                
            } catch (error) {
                console.log("fetch function error", error);
                
            }
        };
        fetchRSS();
        dispatch(loadURL(urlArray));
    }, [dispatch]);

    React.useEffect(() => {
        const actualEpisodeIndex = totalEpisodes - 1 - currentEpisodeIndex;
        dispatch(setCurrentTrackSelection(episodes?.[currentShowPath]?.[actualEpisodeIndex]))
    }, [dispatch, episodes, currentShowPath, currentEpisodeIndex])

    function listenEpisode(index) {
        // const actualEpisodeIndex = totalEpisodes - 1 - index;
        dispatch(setCurrentTrackPath(parseInt(index))) // This sets the track path using the reversed index
        dispatch(setCurrentTrackSelection(episodes?.[currentShowPath]?.[index])) // This sets the actual episode data using the actual index
        dispatch(setSliderTime(0));
    }
    

    const totalEpisodes = episodes[currentShowPath]?.length || 0;

    return (
        <>
            <h1 id="top-episodes" className="section-title-alt">{utils.ShowTitleFormatter(urlArray[2])}</h1>
            { (episodes[currentShowPath]===undefined) ? <h1 className="loading-text">Cargando...</h1> :
                episodes[currentShowPath].reverse().map((episode, index) => {
                    const reversedIndex = totalEpisodes - 1 - index;
                    return (
                        <div key={reversedIndex} className="show-episodes">
                        {/* {console.log("show path", episodes[currentShowPath])} */}
                        {/* {console.log(logState)} */}
                            <Link onClick={() => {
                                    listenEpisode(index)
                                    window.scrollTo(0, 0)
                                }
                            } key={reversedIndex} className="link" to={`/shows/${urlArray[2]}/${reversedIndex}`}>
                                <p className={reversedIndex == selectedEpisodeIndex && "selected-episode"} url={episode.enclosures[0].url}>{utils.DotRemover(episode.title)}</p>
                            </Link>
                            {/* {console.log(episodes[currentShowPath])} */}
                        </div>
                    )
                })
            }
            <img onClick={() => window.scrollTo(0, 0)} className="arrow-up" src={arrowUp} alt="arrow-up" />
        </>
    )
}
