// export const prodURL = "http://104.248.207.222:3000/";
// export const strapiAPI = "http://exp.radioclasica.com.sv:1337";
// export const rssAPI = "http://exp.radioclasica.com.sv:4050/";
// export const prodURL = "http://exp.radioclasica.com.sv:3003/";
// export const streamingURL = "//streaming.mundoclasicaupa.com/radioclasica";
// export const streamingTextURL = "//streaming.mundoclasicaupa.com/liveradio/metadata/icecast/";
export const strapiAPI = "http://www.radioclasica.com.sv:1337";
export const rssAPI = "http:////www.radioclasica.com.sv:4050/mundo/";
// export const rssAPI = "http://localhost:4050/clasica/";
export const prodURL = "http://www.radioelmundo.com.sv/"

export const streamingURL = "http://streaming.mundoclasicaupa.com:8000/radioelmundo";
export const streamingTextURL = "http://streaming.mundoclasicaupa.com/liveradio/metadata/icecast/";

