export function ShowTitleFormatter(title) {
    const showTitles = [
        { "voces": "Voces que Conquistan" },
        { "allthatjazz": "All That Jazz" },
        { "escena": "En Escena" },
        { "prima": "Prima Donna" },
        { "orquestas": "El Show de las Grandes Orquestas" },
        { "beethoven": "Beethoven, el libertador de la música" },
        { "homenaje": "Homenaje a la Vida" },
        { "voz": "Una Voz, una Canción" },
        { "encanto": "El Encanto Armónico" },
        { "notas": "Notas de Europa" },
        { "venciendo": "Venciendo Miedos" },
        { "bienestar": "Bienestar con Mirella Wollants" },
        { "pasion": "Pasión por el Piano" },
        { "sinatra": "Sinatra and Friends" },
        {"encuentros": "Encuentros"}

        

        
    ]
    let matchingShowOj = showTitles.find(obj => Object.keys(obj).includes(title));
    if (matchingShowOj) {
        let [formattedTitle] = Object.values(matchingShowOj);
        return formattedTitle;
    } else {
        return title;
    }

}

export function DotRemover(targetWord) {
    let word = targetWord ? targetWord : "";
    let wordLength = word?.length - 1;
    if (word?.[wordLength] === ".") {
        return word?.slice(0, -1)
    } else if (word?.includes("Venciendo Miedos")) {
        return word?.replace("Venciendo Miedos y Temores en Busca de Mejorar la Vida del", "");
    } else if (word?.includes("Homenaje")) {
        return word?.replace("Homenaje a la Vida del", "")
    } else {
        return word;
    }
}

export function eventsSorter(events) {
    // return events?.filter(event => event.attributes.title === "Día del Piano");
    return events

}