import logoBlanco from "../../../assets/logo-mundo-blanco.svg";
import phone from "../../../assets/phone.svg";
import location from "../../../assets/location.svg";
import email from "../../../assets/email.svg";
import { NavLink } from "react-router-dom";

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer-left">
            <NavLink to="/">
                <img src={logoBlanco} alt="logo Radio El Mundo" />
            </NavLink>
            </div>
            <div className="footer-right">
            <h2>CONTACTO</h2>
            <p className="footer-bullet"><img className="footer-image" src={phone} alt="phone"/> +503 2226-4441</p>
            <p className="footer-bullet"><img className="footer-image" id="footer-email--logo" src={email} alt="phone"/> radios@mundoclasicaupa.com</p>
            <p className="footer-bullet"><img className="footer-image" src={location} alt="phone"/> Final 5a. Avenida Norte El Salvador, <br/> C. A, C. Universitaria Nte., Mejicanos, El Salvador</p>
                {/* <img src={logoBlanco} alt="logo Radio Clásica" /> */}
            </div>
        </div>
    )
}