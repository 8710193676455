import React from "react";
import MetaData from "../partials/header/MetaData";
import { prodURL } from "../../utils";

export default function Nosotros() {

  return (
    <>
      <MetaData title="Nosotros" description="¿Quienes somos?" url={`${prodURL}/nosotros`}  image="https://picsum.photos/200" />

      <h1 className="section-title-alt">¿Quienes somos?</h1>
      <div className="about-section">
        <h2 className="nosotros-title">Fundada el 15 de junio de 1962</h2>
        <p className="about-text" id="left-border">
        Radio El Mundo nació un 15 de junio de 1962, en la frecuencia 1140Khz. Era un formato innovador de música instrumental de todos los géneros y rincones del mundo. Su misión: ser un remanso de paz, positivismo y buen gusto, con selectas voces. Los patrocinadores que apoyaron el concepto marcaron una diferencia en sus mensajes, un estilo que marcó pauta de un nuevo estilo de comunicación.
A sus 60 años, Radio El Mundo se ha convertido en un patrimonio nacional e internacional. <br/><br/> Fue de las pioneras en el espectro del FM Estereo en la frecuencia 93.7Mhz. Radio El Mundo se ha mantenido fiel a sus ideales : ‘mensajes comerciales nunca en Domingo’ y existimos : ‘Porque la música es el lenguaje de las almas’
       
       </p>
      </div>
    </>

  )
}