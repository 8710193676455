import React from "react";
// redux stuff
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setStreamPlaying } from "../../../redux/streamingReducer";
import {  setPaused, setCurrentTrackTime, setSliderTime } from '../../../redux/playerReducer';

// icons for the player
import play from "../../../assets/play.svg";
import pause from "../../../assets/pause.svg";
import { useTabsList } from "@mui/base";

import * as utils from "../../../utils";

export default function Streaming() {
    // redux stuff
  const isStreamPlaying = useSelector(state => state.streaming.isStreamPlaying);
  const dispatch = useDispatch(!isStreamPlaying);

  // streaming player handles
  function handlePlay() {
    dispatch(setStreamPlaying(!isStreamPlaying))
    return (
      document.getElementById('audioPlayer').play()
    )
  }
  function handlePause() {
    dispatch(setStreamPlaying())
    return (
      document.getElementById('audioPlayer').pause()
    )
  }

React.useEffect(()=>{
  // Below forces pause of the player - when streaming is on player must be off and viceversa
  dispatch(setPaused(true));
  dispatch(setCurrentTrackTime(0));
  dispatch(setSliderTime(0));
  
}, [dispatch])

    return (
        <div className="streaming-div">
        
          <div id="radio-en-linea" >
          
            <h1>RADIO EN LINEA</h1>
            {/* -------- Player starts -------*/}
            {!isStreamPlaying ? <img className='streamButton' src={play} alt='play' onClick={handlePlay} /> : <img className='streamButton' src={pause} alt='pause' onClick={handlePause} />}
          </div>
          <audio
            id="audioPlayer"
            src={utils.streamingURL}
          ></audio>
       
        
        {/* -------- Player ends ---------*/}
        {/* -------- Current song text start ------ */}
        {/* <iframe
          // onLoad={()=>{
          // console.log("Streaming Loaded")
          // setAudioState(prev=>({
          //   ...prev,
          //   isTextLoaded:true
          // }))
          // }}
          title="streaming"
          className="streaming"
          height="60"
          frameBorder="0"
          scrolling="no"
          width="100%"
          // style={dynamicStyle}
          src= {utils.streamingTextURL}
          allowtransparency="true"
        ></iframe> */}
        {/* -------- Current song text end ------ */}
      </div>
    )
}