import React from "react";
import Articulos from "../../partials/blog/Articulos";
import MetaData from "../../partials/header/MetaData";
import { prodURL } from "../../../utils";

export default function Inicio() {


    return (
        <>
            <MetaData title="Radio El Mundo" description="El Lenguaje de las Almas" url={`${prodURL}`}  image="https://picsum.photos/200" />
            <Articulos />
      
        </>
    )
}