import { combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createStore } from "redux";
import streamingReducer from "./streamingReducer";
import blogReducer from "./blogReducer";
import playerReducer from "./playerReducer";
import showsReducer from "./showsReducer";
import eventsReducer from "./eventsReducer";

const rootReducer = combineReducers({
  streaming: streamingReducer,
  blog: blogReducer,
  player: playerReducer,
  shows: showsReducer,
  events: eventsReducer
  //   forms: dealModificationReducer,
  //   modals: modalsReducer,
  //   createDeal: createDealFormReducer,
  //   createComment: createCommentFormReducer,
  //   customers: customersReducer,
  //   createCustomer: createCustomerFormReducer,
  //   updateCustomer: customerModificationReducer,
  //   users: usersReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));
//Subscribe function below, I disabled it because it pollutes the console too much with each change
// store.subscribe(() =>
//   console.log("current store states are: ", store.getState())
// );


export default store;
