


export const fetchPosts = async () => {
    const qs = require("qs");
    const query = qs.stringify(
        {
            populate: "*",
        },
        {
            encodeValuesOnly: true,
        }
    );
    const postsURL = `http://exp.radioclasica.com.sv:1337/api/posts?${query}`;
    const posts = await fetch(postsURL);
    return posts.json();
  }

  export const fetchShows = async () => {
    const qs = require("qs");
    
    const query = qs.stringify(
        {
            populate: "*",
        },
        {
            encodeValuesOnly: true,
        }
    );
    const showsURL = `http://exp.radioclasica.com.sv:1337/api/podcasts?${query}`;
    const listOfShows = await fetch(showsURL);
    return listOfShows.json()
  }

  export const fetchEvents = async () => {
    const qs = require("qs");
    const query = qs.stringify(
        {
            populate: "*",
        },
        {
            encodeValuesOnly: true,
        }
    );
    const eventsURL = `http://exp.radioclasica.com.sv:1337/api/events?${query}`;
    const listOfEvents = await fetch(eventsURL);
    return listOfEvents.json()
  }

