import React from "react";
import { useParams } from "react-router-dom";
import { loadPosts } from "../../../redux/blogReducer";
import { useSelector, useDispatch } from "react-redux";
import { loadURL } from "../../../redux/playerReducer";
import { Link } from "react-router-dom";
//Social sharing tools
import Helmet from "react-helmet";
import MetaData from "../header/MetaData";
import { prodURL } from "../../../utils";



import { FacebookButton, FacebookCount } from "react-social";
import FB from "../../../assets/facebook.svg";
import Skeleton from '@mui/material/Skeleton';


import * as utils from "../../../utils";

export default function Articulo() {
    const postsData = useSelector(state => state.blog);
    const dispatch = useDispatch();
    let { articulo } = useParams();
    const articuloId = parseInt(articulo);
    const urlInfoForDynamicBanner = window.location.pathname.split('/')[1]
    const imgSource = `${utils.strapiAPI}${postsData.find(item => item.id === articuloId)?.attributes?.image?.data?.attributes?.formats?.small?.url}`
    const currentPath = window.location.pathname;
    const socialShareURL = `${utils.prodURL}${currentPath}`;

    React.useEffect(() => {
        dispatch(loadPosts());
        dispatch(loadURL(urlInfoForDynamicBanner))
    }, [dispatch, urlInfoForDynamicBanner])


    const skeletonStructure = (
        <Skeleton variant="rectangular" width={210} height={60} />
    );

    return (
        (postsData?.length === 0) ?
            (
                <h1 className="loading-text">Cargando...</h1>

            ) :
            (
                <div>
                    <MetaData title="Articulo" description="¿Quienes somos?" url={`${prodURL}/nosotros`}  />
                    <div className="post-container">
                        <h1 className="post-title" id="top">{postsData.find(item => item.id === articuloId)?.attributes?.title}</h1>
                        <div className="post-content">
                            <img className="post-image" alt="post-image" src={`${utils.strapiAPI}${postsData.find(item => item.id === articuloId)?.attributes?.image?.data?.attributes?.formats?.small?.url}`} />
                            <p className="post-text">{postsData.find(item => item.id === articuloId)?.attributes?.content}</p>
                        </div>

                    </div>
                    <div className="post-share--container">
                        {/* --------- Facebook button to be reinstated once this app is running ---  */}
                        <FacebookButton url={socialShareURL} appId={1395231614634933} style={{ "backgroundColor": "white", "border": "0px" }}>
                            <div className="post-share--buttons">
                                <img className="button-facebook" src={FB} alt="share" />
                                <p className="post-share--text">COMPARTIR</p>
                            </div>
                        </FacebookButton>

                    </div>
                    <section className="carousel-container" >
                        <h2 className="sub-section-title">Más articulos</h2>
                    </section>
                    <div className="carousel">
                        {postsData?.sort(utils.sortDataFunction).reverse().map((post) => {
                            if (post.id !== articuloId) {
                                return (
                                    <div className="carousel-cards" key={post.attributes.title}>
                                        <Link className="link" to={`/articulos/${post.id}`} onClick={() => window.scrollTo(0, 0)}>
                                            <div className="carousel-card--content">
                                                <h2 className="carousel-card--title">
                                                    {post.attributes.title.toUpperCase()}
                                                </h2>
                                                <p className="carousel-card--text">{post.attributes.content.split(" ").slice(0, 15).join(" ")}...</p>
                                                
                                                <p className="carousel-card--date">{utils.dateFormatter(post?.attributes.createdAt)}</p>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            }
                        }).reverse()}
                    </div >

                </div >
            )


    )
}