import React from "react";
import { NavLink, Link } from "react-router-dom";
import mundo from "../../../assets/logo-mundo-rojo.svg"

export default function Navigation() {
    return (
        <div className="head">
            <div className="logo-clasica--container">
                <NavLink to="/">
                    <img src={mundo} id="logo-clasica" alt="logo-clasica" />
                </NavLink>
            </div>

            <ul className="pages">
                <NavLink to="/" className="link" activeclassname="selected-NavLink"><li>STREAMING</li></NavLink>
                <NavLink to="/shows" className="link" activeclassname="selected-NavLink"><li>SHOWS</li></NavLink>
                {/* <NavLink to="/eventos" className="link" activeclassname="selected-NavLink"><li>EVENTOS</li></NavLink> */}
                <NavLink to="/nosotros" className="link" activeclassname="selected-NavLink"><li>NOSOTROS</li></NavLink>
            </ul>
        </div>

    )
}