import { fetchEvents } from "./app/api";


const initialState = {
    filter: {
        date: "05/05/2023",
        type: "concierto",
        name: ""

    },

    events: [
       'testEvent'
        // if required we can initialize our state with a random variable to avoid any issues
        
    ]
}

export function loadEvents(url) {
    return {
        type: "loadEvents",
        payload: url
    }
};


export default function eventsReducer(state = initialState, action) {
    switch (action.type) {
        case "loadEvents":
            return {
                ...state, 
                events: action.payload
            };
        default:
            return state;
    }
};

