import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { loadShowsRSS } from "../../../redux/showsReducer";
import { loadURL, setCurrentTrackSelection, setCurrentTrackPath, setSliderTime } from "../../../redux/playerReducer";
import { Link } from "react-router-dom";
import arrowUp from "../../../assets/arrow-up.svg"
import * as utils from '../../../utils';



export default function ListOfEpisodes() {
    const episodes = useSelector(state => state.shows);
    const selectedEpisodeIndex = useSelector(state => state.player.selectedEpisodeIndex)
    const dispatch = useDispatch();
    const currentPath = window.location.pathname;
    const urlArray = currentPath.split("/");
    const currentShowPath = urlArray[2];
    const currentEpisodeIndex = urlArray[3];



    React.useEffect(() => {
        const { parse } = require("rss-to-json");
        const fetchRSS = async () => {
            try {
                const response = await parse(
                    `${utils.rssAPI}${currentShowPath}`
                );
                let reversedEpisodes = response?.items;
                let episodes = reversedEpisodes.reverse();
                dispatch(loadShowsRSS(currentShowPath, episodes));
                { console.log("episodes are", episodes) }
            } catch (error) {
                console.log("fetch function error", error);
            }
        };
        fetchRSS();
        dispatch(loadURL(urlArray));
    }, [dispatch]);

    React.useEffect(() => {
        const actualEpisodeIndex = totalEpisodes - 1 - currentEpisodeIndex;
        dispatch(setCurrentTrackSelection(episodes?.[currentShowPath]?.[actualEpisodeIndex]))
    }, [dispatch, episodes, currentShowPath, currentEpisodeIndex])

    function listenEpisode(index) {
        // const actualEpisodeIndex = totalEpisodes - 1 - index;
        dispatch(setCurrentTrackPath(parseInt(index))) // This sets the track path using the reversed index
        dispatch(setCurrentTrackSelection(episodes?.[currentShowPath]?.[index])) // This sets the actual episode data using the actual index
        dispatch(setSliderTime(0));
    }


    const totalEpisodes = episodes[currentShowPath]?.length || 0;
    

    return (
        <>
            <h1 id="top-episodes" className="section-title-alt">Encuentros</h1>
            <div className="estreno-page--container">
                <p><strong>Encuentros</strong> es un programa producido por Radio Clásica y tiene como objetivo conocer y difundir el trabajo que realizan los intelectuales salvadoreños y latinoamericanos en las áreas de filosofía, crítica literaria y artística. Nosotros creemos fielmente que la labor de un pensador no finaliza con la publicación de un libro, tan importante es su divulgación y tal actividad va más allá del simple comentario. En ese sentido, <strong>Encuentros</strong> pretende ser un espacio donde se pueda dialogar y reflexionar con teóricos acerca de su labor y producción intelectual. Además, queremos introducir en la cultura del país un diálogo que vaya más allá de la pleitesía, ya que solo en una conversación donde se muestren con honestidad y respeto las diferencias puede brotar un auténtico y genuino pensamiento.</p>
                <h2>Capitulo 1 : Entre Biógrafo y Biografiado: Descubriendo el Teatro de Roberto Salomón con David J. Rocha Cortez</h2>
                <div className="estreno-page--episode">
                <p>En esta oportunidad, nuestros invitados son  <strong>David J. Rocha Cortez</strong>, autor de "Convergencias: Una mirada a la poética teatral de Roberto Salomón", publicada en Índole Editores; y <strong>Roberto Salomón</strong>, el director teatral vivo más importante de El Salvador.
                    La conversación gira en torno al libro de Rocha, donde con gran maestría narra la trayectoria vital de Salomón y, además, analiza perspicazmente toda su dramaturgia. Pero el placer de este diálogo no finaliza ahí, pues en pocas ocasiones se puede reunir al biógrafo y al mismo tiempo al biografiado. Así pues, "Encuentros" tiene también el honor de que Roberto Salomón nos explique el significado de hacer teatro en Europa y en El Salvador.</p>
                <iframe  src="https://www.youtube.com/embed/FfrC8KLUP7I?si=u4DbU57p75eVw4l9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                

            </div>
                <img onClick={() => window.scrollTo(0, 0)} className="arrow-up" src={arrowUp} alt="arrow-up" />
        </>
    )
}
