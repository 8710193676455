import React from "react";

export default function Confidencialidad() {
    return (
        <div className="legal">

            <h1 className="section-title-alt">Términos y Condiciones de Uso</h1>
            <p className="about-text">
                Bienvenido a nuestro sitio web, operado por Radio El Mundo S.A. de C.V.
        
                ("nosotros", "nos" o "nuestro"). Al utilizar nuestro sitio web,
                usted acepta cumplir con estos términos y condiciones de uso ("Términos"). 
                Si no está de acuerdo con estos Términos, no utilice nuestro sitio web.
            </p>
            <h2>Uso del sitio web</h2>
            <p>
            Nuestro sitio web está destinado a proporcionar información sobre nuestra radio cultural, programas y actividades relacionadas. También utilizamos la herramienta de análisis web llamada Matomo para recopilar datos estadísticos anónimos sobre el uso del sitio web. La información recopilada se utiliza únicamente para mejorar nuestro sitio web y comprender mejor las necesidades de nuestros usuarios. No recopilamos ni almacenamos información personal identificable.
            </p>
            <h2>Propiedad intelectual</h2>
            <p>
            Todo el contenido publicado en nuestro sitio web, incluyendo, entre otros, texto, gráficos, logotipos, imágenes, clips de audio, clips de video y software, es propiedad de nuestra radio cultural o de terceros que nos han otorgado permiso para utilizar su contenido. Este contenido está protegido por leyes de propiedad intelectual, incluyendo, entre otros, derechos de autor y marcas comerciales.

Los usuarios tienen derecho a reproducir y compartir el contenido de nuestro sitio web en redes sociales u otros medios de comunicación siempre y cuando no se utilice con fines comerciales. Queda estrictamente prohibido copiar, distribuir, modificar, transmitir, exhibir, publicar, vender, licenciar o explotar cualquier contenido del sitio web para fines comerciales o no comerciales sin nuestro consentimiento previo por escrito.
            </p>
            <h2>Cambios en los Términos y Condiciones</h2>
            <p>Nos reservamos el derecho de actualizar o modificar estos Términos en cualquier momento sin previo aviso. Los cambios entrarán en vigencia inmediatamente después de su publicación en nuestro sitio web. Es responsabilidad del usuario revisar los Términos y Condiciones periódicamente para estar al tanto de cualquier cambio. Si continúa utilizando nuestro sitio web después de la publicación de los cambios, se considerará que ha aceptado los nuevos Términos y Condiciones.</p>
            <h1 className="section-title-alt">Política de Confidencialidad</h1>
            <p>En radio El Mundo S.A. de C.V. nos tomamos muy en serio la privacidad de nuestros usuarios. Esta política de confidencialidad describe cómo recopilamos, utilizamos y protegemos la información personal identificable que recopilamos a través de nuestro sitio web.</p>
            <h2>Recopilación de información</h2>
            <p>Utilizamos la herramienta de análisis web Matomo para recopilar información anónima sobre el uso de nuestro sitio web. Esta información incluye la dirección IP del usuario, el tipo de navegador utilizado, la página visitada, la hora y la fecha de la visita, y otros datos estadísticos. Esta información se utiliza únicamente para mejorar nuestro sitio web y comprender mejor las necesidades de nuestros usuarios. No recopilamos ni almacenamos información personal identificable.</p>
            <h2>Uso de la información</h2>
            <p>La información recopilada a través de Matomo se utiliza únicamente para fines estadísticos. No vendemos ni compartimos esta información con terceros. La información se utiliza exclusivamente para mejorar nuestro sitio web y comprender mejor las necesidades de nuestros usuarios.</p>
           
        </div>
    )
}