import React from "react";
import Streaming from "./streaming/Streaming";
import Player from "./player/Player";
import { useSelector } from "react-redux";
export default function Banner() {
  const currentSection = useSelector(state => state.player.banner)
  
  return (
    <div  className="parallax-container">
      <div className="parallax" id="parallax-inicio">
        {currentSection?.includes("shows") && currentSection?.length > 2 ? <Player /> : <Streaming /> }
        
      </div>
      
    </div>
  );
};
