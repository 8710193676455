export function setStreamPlaying(bool) {
    return {
        type: "setStreamPlaying",
        payload: bool,
    };
};

const initialState = {
    isStreamPlaying: false
};

export default function streamingReducer(state=initialState, action) {
    switch(action.type) {
        case "setStreamPlaying":
            return {
                ...state,
                isStreamPlaying: action.payload
            };
        default:
            return state;
    }
};

