import { fetchPosts } from "./app/api";

export function loadPosts() {
    return async(dispatch, getState) => {
        const payload = await fetchPosts();
        dispatch({
            type: "loadPosts",
            payload
        })
    }
};

const initialState = [
    // if required we can initialize our state with a random variable to avoid any issues
    
];

export default function blogReducer(state = initialState, action) {
    switch (action.type) {
        case "loadPosts":
            return [...action.payload.data];
        default:
            return state;
    }
};

