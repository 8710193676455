//I initiated the value in order to avoid the undefined error when data is not charged yet
const initialState = {
    banner: ["", "", ""],
    currentTrackSelection: {
        title: "",
        content: "",
        description: "",
        enclosures: [{ url: "" }]
    },
    currentShowSelection: [],
    paused: true,
    currentTrackTime: 0,
    sliderTime: 0,
    currentTrackPath: 0,
    currentVolume: 0.5,
    selectedEpisodeIndex: 0
};

export function loadURL(url) {
    return {
        type: "loadURL",
        payload: url
    }
};


export function setSelectedEpisode(episode) {
    return {
        type: "setSelectedEpisode",
        payload: episode
    }
}
export function setCurrentTrackSelection(track) {
    return {
        type: "setCurrentTrackSelection",
        payload: track
    }
}

export function setCurrentShowSelection(show) {
    return {
        type: "setCurrentShowSelection",
        payload: show
    }
}

export function setPaused(el) {
    return {
        type: "setPaused",
        payload: el
    }
}

export function setCurrentTrackTime(time) {
    return {
        type: "setCurrentTrackTime",
        payload: time
    }
}

export function setSliderTime(time) {
    return {
        type: "setSliderTime",
        payload: time
    }
}

export function setCurrentTrackPath(path) {
    return {
        type: "setCurrentTrackPath",
        payload: path
    }
}


export function setCurrentVolume(value) {
    return {
        type: "setCurrentVolume",
        payload: value
    }
}

export default function playerReducer(state = initialState, action) {
    switch (action.type) {
        case "loadURL":
            return {
                ...state,
                banner: action.payload
            }
        case "setCurrentTrackSelection":
            return {
                ...state,
                currentTrackSelection: action.payload
            }
        case "setCurrentShowSelection":
            return {
                ...state,
                currentShowSelection: action.payload
            }
        case "setPaused":
            return {
                ...state,
                paused: action.payload
            }
        case "setCurrentTrackTime":
            return {
                ...state,
                currentTrackTime: action.payload
            }
        case "setSliderTime":
            return {
                ...state,
                sliderTime: action.payload
            }
        case "setCurrentTrackPath":
            return {
                ...state,
                currentTrackPath: action.payload
            }
        case "setCurrentVolume":
            return {
                ...state,
                currentVolume: action.payload
            }
        case "setSelectedEpisode":
            return {
                ...state,
                selectedEpisodeIndex: action.payload
            }
        default:
            return state;
    }
};
