import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadPosts } from "../../../redux/blogReducer";
import { loadURL } from "../../../redux/playerReducer";
import * as utils from "../../../utils";
import MetaData from "../header/MetaData";
import { prodURL } from "../../../utils";

export default function Articulos() {
    const postsData = useSelector(state => state.blog);
    const dispatch = useDispatch();
    const urlInfoForDynamicBanner = window.location.pathname.split('/')[1]


    React.useEffect(() => {
        dispatch(loadPosts());
        dispatch(loadURL(urlInfoForDynamicBanner));
    }, [dispatch, urlInfoForDynamicBanner])

    return (
        <>
    
            <h1 className="section-title-alt">Actualidad</h1>
            {
                (postsData?.length === 0) ?
                    (
                        <h1 className="loading-text">Cargando...</h1>

                    ) :
                    (
                        <div className="cards" >
                            {postsData?.sort(utils.sortDataFunction).slice(0, 15).map((item, index) => {
                                const imgSource = `${utils.strapiAPI}${item.attributes.image.data.attributes.formats.small.url}`;
                                return (
                                    <Link key={index} onClick={() => window.scrollTo(0, 0)} className="link item-card" to={`/articulos/${item.id}`} >
                                        <div className="card-content" >
                                            <div className="card-image--container">
                                                <img
                                                    className="card-image"
                                                    alt={item.attributes.title}
                                                    src={imgSource}
                                                />
                                            </div>
                                            <div className="card-title--container">
                                                <h1 className="card-title">{item.attributes.title}</h1>

                                            </div>
                                            <div className="card-text--container">
                                                <p className="card-text--preview">
                                                    {item.attributes.content.split(" ").slice(0, 45).join(" ")}...
                                                </p>
                                            </div>
                                            <button className="link-button">
                                                Leer +
                                            </button>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>

                    )
            }
        </>

    )
}