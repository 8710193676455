import Helmet from "react-helmet";
import clasicaImage from "../../../assets/clasica.png";


export default function MetaData(props) {
    return (
        <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description}/>
        <meta property="og:title" content={props.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={props.description}
        />
        <meta
          property="og:url"
          content={props.url}
        />
        {/* I need to correct below, it seems that the crawler will look for a public image */}
        {/* <meta
          property="og:image"
          content={clasicaImage}
        /> */}
      </Helmet>
    )
}