import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadEvents } from "../../../redux/eventsReducer";
import { loadURL } from "../../../redux/playerReducer";
import * as utils from "../../../utils";
// import MetaData from "../header/MetaData";
import { prodURL } from "../../../utils";
// import { eventsSorter } from "../../../utils/TextUtils";

export default function Eventos() {

    // BELOW IS TEMPORARY - WILL BE HANDLED BY RED
    const [filters, setFilters] = React.useState({
        concierto: false,
        teatro: false,
        cena: false,
    });

    const filterData = useSelector(state => state?.events?.filter)
    const eventsData = useSelector(state => state?.events?.events);
    const dispatch = useDispatch();
    const urlInfoForDynamicBanner = window.location.pathname.split('/')[1]


    React.useEffect(() => {
        dispatch(loadEvents("test2"));
        dispatch(loadURL(urlInfoForDynamicBanner));
    }, [dispatch, urlInfoForDynamicBanner])


    return (
        <>
            <h1 className="section-title-alt">Cartelera Cultural</h1>        
            <div className="searchbar-container">

            {console.log(eventsData)}
                {/* ADVANCED FILTERS --- UNDER DEVELOPMENT */}
              
            </div>
            <div className="cards" >
            
            
                {/* {utils.eventsSorter(eventsData)?.slice(0, 15).map((item, index) => {
                    const imgSource = `${utils.strapiAPI}${item?.attributes?.picture.data.attributes.formats.small.url}`;
                    return (
                        <Link key={index} onClick={() => window.scrollTo(0, 0)} className="link item-card" to={`/eventos/${item.id}`} >
                            <div className="card-contment" >
                                
                                {imgSource && <div className="card-image--container">
                                    <img
                                        className="card-image"
                                        alt={item.attributes.title}
                                        src={imgSource}
                                    />
                                    
                                </div>}
                                <div className="card-title--container">
                                    <p >
                                        {item.attributes.type}
                                    </p>
                                    <h1 className="card-title">{item.attributes.title}</h1>
                                </div>
                                <div className="card-text--container">
                                    <p className="card-text--preview">
                                        {item.attributes.description.split(" ").slice(0, 45).join(" ")}...
                                    </p>
                                </div>
                                <div className="card-text--container">
                                    <p >
                                        Fecha: {item.attributes.date}
                                    </p>
                                    <p >
                                        Lugar: {item.attributes.address}
                                    </p>
                                </div>
                                <button className="link-button">
                                    Leer +
                                </button>
                            </div>
                        </Link>
                    )
                })} */}
            </div>

        </>


    )
}