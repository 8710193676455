import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadURL, setCurrentTrackTime, setSliderTime } from "../../../redux/playerReducer";
import { loadShows } from "../../../redux/showsReducer";
import { useNavigate } from "react-router-dom";
import MetaData from "../../partials/header/MetaData";
import { prodURL } from "../../../utils";
import caratulaEncuentros from "../../../assets/encuentros_caratula.png"

import { Link } from "react-router-dom";
//Social sharing tools
import Helmet from "react-helmet";
import { FacebookShareButton, FacebookIcon } from 'react-share';
import FB from "../../../assets/facebook.svg"

import * as utils from "../../../utils";

export default function Shows() {
    const navigate = useNavigate();
    const shows = useSelector(state => state?.shows?.listOfShows?.data)
    const dispatch = useDispatch();
    const currentPath = window.location.pathname;
    const urlArray = currentPath.split("/");
    const urlInfoForDynamicBanner = window.location.pathname.split('/')[1];

    React.useEffect(() => {
        dispatch(loadShows());
        dispatch(loadURL(urlArray));
        dispatch(setCurrentTrackTime(0));
        dispatch(setSliderTime(0));
    }, [urlInfoForDynamicBanner])


    function handleOpenShow(id) {
        return (
            navigate(`/shows/${id}/0`)
        )
    }

    return (
        <>
            {/* <MetaData title="Shows" description="Shows culturales" url={`${prodURL}/shows`}  /> */}
            <h1 className="section-title-alt" >Nuestros Shows</h1>
            <div className="shows-container">
                {console.log(shows)}
                {/* <div className="estreno">
                    <h2 className="estreno-title">Programa Destacado</h2>
                    <Link className="link" to={`/featured`} >
                        <div className="estreno-content">
                            <img className="caratula-estreno" src={caratulaEncuentros}></img>
                            <div className="estreno-content--text">
                            <h3>Encuentros</h3>
                                <p>
                                    Encuentros es un programa producido por Radio Clásica y tiene como objetivo conocer y difundir el trabajo que realizan los intelectuales salvadoreños y latinoamericanos en las áreas de filosofía, crítica literaria y artística. Nosotros creemos fielmente que la labor de un pensador no finaliza con la publicación de un libro, tan importante es su divulgación y tal actividad va más allá del simple comentario. En ese sentido, Encuentros pretende ser un espacio donde se pueda dialogar y reflexionar con teóricos acerca de su labor y producción intelectual. Además, queremos introducir en la cultura del país un diálogo que vaya más allá de la pleitesía, ya que solo en una conversación donde se muestren con honestidad y respeto las diferencias puede brotar un auténtico y genuino pensamiento.
                                </p>
                                <button className="show-card--button">Escuchar</button>
                            </div>
                        </div>
                    </Link>

                </div> */}
                {(shows === undefined) ? <h1 className="loading-text">Cargando...</h1> : shows?.filter(el => el.attributes?.radio == 'mundo').map((show, index) => {
                    return (
                        <Link className="link" to={`/shows/${show?.attributes?.url}/0`} >
                            <div className="show-card" key={index} >
                                <div className="show-card--identification">
                                    <h2 className="show-card--title">{show?.attributes?.title}</h2>
                                    <img className="show-card--image" src={`${utils.strapiAPI}${show.attributes?.image?.data?.attributes?.formats?.small?.url}`} />
                                </div>
                                <div className="show-card--content">
                                    <p className="show-card--content">{show?.attributes?.description}</p>
                                    <button onClick={() => handleOpenShow(show?.attributes?.url)} className="show-card--button">Escuchar</button>
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </>
    )
}