import './styles/global.css';
import './styles/styles.css';
import './styles/streaming-banner.css';
import './styles/header.css';
import './styles/footer.css';
import './styles/blog.css';
import './styles/eventos.css'
import './styles/skeletons.css';
import './styles/player.css';
import './styles/shows.css';
import './styles/nosotros.css';

import { Routes, Route } from "react-router-dom"
import Inicio from './components/routes/inicio/Inicio';
import Articulo from './components/partials/blog/Articulo';
import Shows from './components/routes/shows/Shows';

import Episode from './components/routes/shows/Episode';
import Featured from './components/routes/shows/Featured';
import Eventos from './components/routes/eventos/Eventos';
import Evento from './components/routes/eventos/Evento';
import Nosotros from './components/routes/Nosotros';
import Header from './components/partials/header/Header';
import Footer from './components/partials/footer/Footer';
import Confidencialidad from './components/routes/legal/Confidencialidad';


function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route exact="true" path="/" element={<Inicio />} />
        <Route path="/articulos/:articulo" element={<Articulo />} />
        <Route path="/shows" element={<Shows />} />

        {/* temporary */}
        <Route path="/featured" element={<Featured />} />

        <Route path="/shows/:show/:episode" element={<Episode />} />
        <Route path="/eventos" element={<Eventos />} />
        <Route path="/eventos/:evento" element={<Evento />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/confidencialidad" element={<Confidencialidad />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
