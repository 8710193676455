import React from "react";
import { useParams } from "react-router-dom";
import { loadEvents } from "../../../redux/eventsReducer";
import { useSelector, useDispatch } from "react-redux";
import { loadURL } from "../../../redux/playerReducer";
import { Link } from "react-router-dom";
//Social sharing tools
import Helmet from "react-helmet";
import MetaData from "../../partials/header/MetaData";
import { prodURL } from "../../../utils";

import { FacebookButton, FacebookCount } from "react-social";
import FB from "../../../assets/facebook.svg";
import Skeleton from '@mui/material/Skeleton';



import * as utils from "../../../utils";

export default function Articulo() {
    const eventsData = useSelector(state => state.events);
    const dispatch = useDispatch();
    let { evento } = useParams();
    const eventoId = parseInt(evento);
    const urlInfoForDynamicBanner = window.location.pathname.split('/')[1]
    const imgSource = `${utils.strapiAPI}${eventsData.find(item => item.id === eventoId)?.attributes?.picture?.data?.attributes?.formats?.small?.url}`
    const currentPath = window.location.pathname;
    const socialShareURL = `${utils.prodURL}${currentPath}`;

    React.useEffect(() => {
        dispatch(loadEvents());
        dispatch(loadURL(urlInfoForDynamicBanner))
    }, [dispatch, urlInfoForDynamicBanner])


    const skeletonStructure = (
        <Skeleton variant="rectangular" width={210} height={60} />
        
    );

    return (
        (eventsData?.length === 0) ?
            (
                <h1 className="loading-text">Cargando eventos...</h1>
                
                
            ) :
            (
                <div>
                    <MetaData title="Evento" description="¿Quienes somos?" url={`${prodURL}/nosotros`} image="https://picsum.photos/200" />
                    <div className="post-container">
                        <h1 className="post-title" id="top">{eventsData.find(item => item.id === eventoId)?.attributes?.title}</h1>
                        {console.log("evento id is", eventsData.find(item => item.id === eventoId)?.attributes?.title)}
                        {console.log("evento id is", eventsData.find(item => item.id === eventoId)?.attributes?.title)}
                          {console.log("evento id is", eventsData.find(item => item.id === eventoId)?.attributes?.title)}
                        <div className="post-content">
                            <img className="post-image" alt="post-image" src={`${utils.strapiAPI}${eventsData.find(item => item.id === eventoId)?.attributes?.picture?.data?.attributes?.formats?.small?.url}`} />
                            <p className="post-text">{eventsData.find(item => item.id === eventoId)?.attributes?.description}</p>
                        </div>

                    </div>
                    <div className="post-share--container">
                        {/* --------- Facebook button to be reinstated once this app is running ---  */}
                        {/* <FacebookButton url={socialShareURL} appId={1395231614634933} style={{ "backgroundColor": "white", "border": "0px" }}>
                            <div className="post-share--buttons">
                                <img className="button-facebook" src={FB} alt="share" />
                                <p className="post-share--text">COMPARTIR</p>
                            </div>
                        </FacebookButton> */}

                    </div>
                    <section className="carousel-container" >
                        <h2 className="sub-section-title">Más articulos</h2>
                    </section>
                    <div className="carousel">
                        {eventsData?.sort(utils.sortDataFunction).reverse().map((event) => {
                            if (event.id !== eventoId) {
                                return (
                                    <div className="carousel-cards" key={event.attributes.title}>
                                        <Link className="link" to={`/eventos/${event.id}`} onClick={() => window.scrollTo(0, 0)}>
                                            <div className="carousel-card--content">
                                                <h2 className="carousel-card--title">
                                                    {event.attributes.title.toUpperCase()}
                                                </h2>

                                                <p className="carousel-card--text">{event.attributes.description.split(" ").slice(0, 15).join(" ")}...</p>
                                                <p className="carousel-card--date">{utils.dateFormatter(event?.attributes.createdAt)}</p>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            }
                        }).reverse()}
                    </div >

                </div >
            )


    )
}