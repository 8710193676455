import * as React from 'react';
import { useNavigate } from "react-router-dom";
import MetaData from '../header/MetaData';

// Material Icons stuff below
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import { Skeleton } from '@mui/material';
// import rodim from "../../../assets/rodim.png"

// Redux stuff
import { setCurrentTrackSelection, setPaused, setCurrentTrackTime, setSliderTime, setCurrentVolume, setSelectedEpisode } from '../../../redux/playerReducer';
import { useSelector, useDispatch } from 'react-redux';
import { setStreamPlaying } from "../../../redux/streamingReducer";

//Useful functions
import * as utils from '../../../utils';


//Social sharing tools
import Helmet from "react-helmet";
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { FacebookButton, FacebookCount } from "react-social";
import FB from "../../../assets/share.svg"


export default function Player() {
    const navigate = useNavigate();

    //Centralized REDUX variables
    const dispatch = useDispatch();
    const episodes = useSelector(state => state.shows);
    const currentTrack = useSelector(state => state.player.currentTrackSelection);
    const paused = useSelector(state => state.player.paused);
    const currentTrackTime = useSelector(state => state.player.currentTrackTime);
    const sliderTime = useSelector(state => state.player.sliderTime);
    const currentShow = useSelector(state => state.player.banner);
    const currentVolume = useSelector(state => state.player.currentVolume);

    // Parameters in URL
    const currentPath = window.location.pathname;
    const urlArray = currentPath.split("/");
    const currentEpisodeList = episodes?.[urlArray[2]];
    const currentEpisodeIndex = parseInt(urlArray[3]);
    const prodURL = `${utils.prodURL}${currentPath}`;
    const shows = useSelector(state => state?.shows?.listOfShows?.data)


    const socialShareURL = prodURL;

    //Below hook allows up to handle the html tag that contains audioPlayer ref, in this case <audio>
    const audioPlayer = React.useRef();



    // ################ Track-Time management logic ##################
    const calculateTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes} : ${returnedSeconds}`;
    };

    function timeConverter(secs) {
        return Math.floor(secs);
    };

    let trackDuration = timeConverter(audioPlayer?.current?.duration);
    let currentTrackTimeInMin = calculateTime(currentTrackTime);
    let remainingTrackTime = trackDuration - currentTrackTime;
    let remainingTrackTimeInMin = calculateTime(remainingTrackTime);

    //   const trackURL = typeof(currentTrack.enclosures[0].url !== undefined) ? currentTrack?.enclosures[0]?.url : "";
    //Pause below when debugging or it will pollute your browser console
    React.useEffect(() => {
        if (!audioPlayer?.current?.paused) {
            const timer = setInterval(() => {
                // Creates an interval which will update the current data every second (this will trigger a rerender each time)
                dispatch(setCurrentTrackTime(audioPlayer?.current?.currentTime));
                // dispatch(setSliderTime(sliderTime))
                dispatch(setSliderTime(audioPlayer.current.currentTime))
            }, 1000);
            return () => {
                clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
            };
        }
    }, [dispatch, paused, audioPlayer?.current?.paused]);


    React.useEffect(() => {
        // Below dispatch allows us to refresh currentTrack instantly whenever a player button is pressed
        dispatch(setCurrentTrackSelection(currentEpisodeList?.[currentEpisodeIndex]))

        //Below pauses streaming - when there is streaming there is not player and viceversa
        dispatch(setStreamPlaying(false));
        dispatch(setSelectedEpisode(currentEpisodeIndex))
        

        // ################# Audio Play/Pause logic #################
        // !paused
        //     ? audioPlayer.current.play()
        //     : audioPlayer.current.pause();
    }, [paused, dispatch, episodes, urlArray, sliderTime, currentEpisodeList, currentEpisodeIndex]);

    function updateTrack() {
        dispatch(setCurrentTrackTime(0));
        dispatch(setSliderTime(0));
    }

    function handleSliderChange(value) {
        // dispatch(setPaused(true))
        const settingNewValuePromise = new Promise(resolve => {
            audioPlayer.current.currentTime = value
            dispatch(setSliderTime(value))
            resolve()
        })
        // settingNewValuePromise.then(() => dispatch(setPaused(false))).then(() => {
        //     console.log("slider is updated")
        // })
        settingNewValuePromise.then(() => {
            console.log("slider is updated")
        })
    }

    function handlePlayTrack() {

        // const promiseA = new Promise(resolve => {
        //     dispatch(setPaused(!paused));
        //     resolve()
        // })
        // promiseA.then(() => {
        //     paused
        //         ? audioPlayer.current.play()
        //         : audioPlayer.current.pause();
        // })
        // dispatch(setCurrentTrackSelection(currentEpisodeList?.[currentEpisodeIndex]));
        audioPlayer?.current?.paused ? audioPlayer.current.play() : audioPlayer.current.pause()
        dispatch(setPaused(audioPlayer?.current?.paused))

    }

    function handlePreviousTrack() {

        updateTrack();
        dispatch(setPaused(true));
        (currentEpisodeIndex > 0) && navigate(`/shows/${urlArray[2]}/${currentEpisodeIndex - 1}`);
        // dispatch(setSelectedEpisode(currentEpisodeIndex))
        audioPlayer?.current?.pause()
    }

    function handleNextTrack() {
        updateTrack();
        dispatch(setPaused(true));
        (currentEpisodeIndex < (episodes?.[urlArray[2]].length - 1)) && navigate(`/shows/${urlArray[2]}/${currentEpisodeIndex + 1}`);
        // dispatch(setSelectedEpisode(currentEpisodeIndex))
        audioPlayer?.current?.pause()
    }



    return (
        <>

            {/* <div id="player" className="parallax-container"> */}
            {/* <div className="parallax" id="parallax-inicio"> */}

            <div id="player" className='player-container'>

                <div className='track-container'>

                    <div className='track-info'>
                        <div className="track-image--container">
                            {currentTrack?.itunes_image?.href ? <img
                                className="track-image"
                                alt="imagen del show"
                                // src="https://picsum.photos/200"
                                src={currentTrack?.itunes_image?.href}
                            /> : <Skeleton variant="rectangular" width={100} height={100} />}
                        </div>
                        <div className="player-text--container">
                       {/* {currentShow[2] == "pasion" ? <img className='logo-sponsor' src={rodim} alt="rodim"/> : ""} */}
                        {console.log(currentShow[2])}
                            <h3 className='player-text'>{utils.DotRemover(currentTrack?.title)}</h3>
                            <h4 className='player-text'>{utils.ShowTitleFormatter(currentShow?.[2])}</h4>
                            {/* {console.log("image is :", episodes[currentShow[2]][0].itunes_image.href)} */}
                        </div>
                    </div>
                    {/* ------Facebook button to be added once app is up --------- */}
                    <FacebookButton url={socialShareURL} appId={1395231614634933} style={{ "backgroundColor": "white", "border": "0px" }}>
                        <img className="button-facebook" src={FB} alt="share" />
                    </FacebookButton>
                </div>
                <Slider
                    className="track-slider"
                    aria-label="time-indicator"
                    size="small"
                    value={sliderTime}
                    min={0}
                    step={1}
                    max={isNaN(trackDuration) ? 0 : trackDuration}
                    onChange={(_, value) => handleSliderChange(value)}
                    sx={{
                        //testing touch actions below
                        touchAction: 'none',
                        color: 'orangered',
                        height: 4,
                        '& .MuiSlider-thumb': {
                            width: 8,
                            height: 8,
                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                            '&:before': {
                                boxShadow: '0 2px 12px 0 rgb(255, 69, 0, 0.3)',
                            },
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: `0px 0px 0px 8px ${'rgb(255, 69, 0, 0.3)'
                                    }`,
                            },
                            '&.Mui-active': {
                                width: 20,
                                height: 20,
                            },
                        },
                        '& .MuiSlider-rail': {
                            opacity: 0.28,
                        },
                    }}
                />
               <div className="slider-time-text">
                    <p className='player-text'>{currentTrackTimeInMin}</p>
                    <p className='player-text'>-{isNaN(trackDuration) ? '00 : 00' : remainingTrackTimeInMin}</p>
                </div> 
              <div className='player-buttons'>
                    <IconButton onClick={handlePreviousTrack} className="player-buttons" aria-label="previous song" sx={{
                        '&:hover': { backgroundColor: 'rgb(255, 247, 244)' }
                    }}>
                        <FastRewindRounded className="player-buttons" fontSize="large" />
                    </IconButton>
                    <IconButton
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgb(255, 247, 244)',
                            }

                        }}
                        aria-label={paused ? 'play' : 'pause'}
                        onClick={handlePlayTrack}
                    >
                        {/* {paused && audioPlayer?.current?.paused ? (
                            <PlayArrowRounded
                                sx={{ fontSize: '3rem' }}
                                className="player-buttons"
                            />
                        ) : (
                            <PauseRounded sx={{ fontSize: '3rem' }} className="player-buttons" />
                        )} */}
                        
                        {/* {console.log("is audioplayer undefined", audioPlayer?.current?.paused === undefined)} */}
                        {/* {console.log("logic test", (audioPlayer?.current?.paused && audioPlayer?.current?.paused !== undefined))} */}



                        {(audioPlayer?.current?.paused && paused) ?
                            <PlayArrowRounded
                                sx={{ fontSize: '3rem' }}
                                className="player-buttons"
                                disabled={true}
                            />
                        :
                            <PauseRounded sx={{ fontSize: '3rem' }} className="player-buttons" />
                        }

                        {/* {console.log(audioPlayer?.current?.readyState)} */}
                        <audio
                            ref={audioPlayer}
                            src={currentTrack?.enclosures[0].url}
                            preload="metadata"
                        />

                            {console.log(currentTrack?.itunes_image?.href)}
                    </IconButton>
                    <IconButton onClick={handleNextTrack} aria-label="next song" sx={{ '&:hover': { backgroundColor: 'rgb(255, 247, 244)' } }}>
                        <FastForwardRounded fontSize="large" className="player-buttons" />
                    </IconButton>
                </div> 

                <div className="volume-slider">
                    <VolumeDownRounded className="player-buttons" />
                    <Slider
                        aria-label="Volume"
                        defaultValue={1}
                        min={0}
                        step={0.1}
                        max={1}
                        value={currentVolume}
                        onChange={(_, value) => {
                            dispatch(setCurrentVolume(value));
                            audioPlayer.current.volume = value
                        }}
                        sx={{
                        //testing touch actions below
                        touchAction: 'none',
                        color: 'orangered',
                        height: 4,
                        '& .MuiSlider-thumb': {
                            width: 8,
                            height: 8,
                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                            '&:before': {
                                boxShadow: '0 2px 12px 0 rgb(255, 69, 0, 0.3)',
                            },
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: `0px 0px 0px 8px ${'rgb(255, 69, 0, 0.3)'
                                    }`,
                            },
                            '&.Mui-active': {
                                width: 20,
                                height: 20,
                            },
                        },
                        '& .MuiSlider-rail': {
                            opacity: 0.28,
                        },
                    }}
                    />
                    <VolumeUpRounded className="player-buttons" />
                </div>

            </div>

            {/* </div> */}

            {/* </div> */}
        </>
    );
}