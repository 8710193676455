import React from 'react';
import ListOfEpisodes from './ListofEpisodes';
import MetaData from '../../partials/header/MetaData';
import { useParams } from "react-router-dom";
// import adImberton from "../../../assets/ad-imberton.png"


export default function Episode() {
    const {show} = useParams()
    return (
        <>        
        <MetaData title="Shows" description="Shows culturales" url={window.location.href} />
        <div className="ad-banner">
        {/* <img src={adImberton} alt="arrow-up" /> */}
        {show == "pasion" && 
        <p>Pasion</p>
    
        
        }
        {console.log("yo", show)}
            {/* Buscar imagen publicitaria de strapi */}
        </div>
            <ListOfEpisodes />
 
        </>
    )
}