import { fetchShows } from "./app/api";

const initialState = {
    test:"",

};

export function loadShows() {
    return async (dispatch, getState) => {
        const payload = await fetchShows();
        dispatch({
            type: "loadShows",
            payload
        });
    };
};

export function loadShowsRSS(show, elements) {
    return {
        type: "loadShowsRSS",
        payload: {
            show,
            elements
        }
    }
}

export default function showsReducer(state = initialState, action) {
    switch (action.type) {
        case "loadShows":
            return {
                ...state,
                listOfShows: action.payload
            };
        case "loadShowsRSS":
            return {
                ...state,
                [action.payload.show]: action.payload.elements
            };
        default:
            return state;
    }
};
