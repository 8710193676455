import React from "react";
import Navigation from "./Navigation";
import Banner from "../Banner";


export default function Header() {
    return (
        <nav>
            <Navigation />
            <Banner />
        </nav>
    )
}